.spanFilter{
    margin-left: 10px !important;
}
@media screen and (max-width: 1703px) {
    .divPlantsFiltersV1{
        overflow: auto !important;
        width: 40rem !important;
    }

    .divPlantsFiltersV2{
        display: flex !important; 
        flex-wrap: nowrap !important;
        width: 90vh !important;
        gap: 0.5rem !important;
    }
}

@media screen and (max-width: 1424px) {
    .divPlantsFiltersV1{
        overflow: auto !important;
        width: 23rem !important;
    }

    .divPlantsFiltersV2{
        display: flex !important; 
        flex-wrap: nowrap !important;
        width: 90vh !important;
        gap: 0.5rem !important;
    }
}





