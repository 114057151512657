.divSpinnerTablePlants{
    display: flex !important; 
    justify-content: center !important; 
    height: 30rem !important; 
    align-items: center !important
}

.spinnerTablePlants{
    width: 3rem !important; 
    height: 3rem !important;
}

.responsiveHeight {
    width: 100% !important;
    height: 40rem !important;
}


.divGlobalMap{
    border-radius: 10px !important;
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
}

.divGlobalMapV2{
    display: flex !important;
    flex-direction: row !important; 
    justify-content: space-between !important;
    gap: 1rem !important
}



@media screen and (max-width: 1200px) {
.responsiveHeight {
    height: 42vh !important;
}

.divGlobalMapV2{
    display: flex !important;
    flex-direction: column !important; 
    gap: 1rem !important
}

@media screen and (max-height: 930px) {
    .responsiveHeight {
        height: 40vh !important;
    }
}

@media screen and (max-height: 850px) {
    .responsiveHeight {
        height: 35vh !important;
    }
}

@media screen and (max-height: 750px) {
    .responsiveHeight {
        height: 27vh !important;
    }
}
}