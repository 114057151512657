.divLoadingSpan{
    width: 100%; 
    display: flex; 
    justify-content: center; 
    height: 15rem; 
    align-items: center;
}
.divTitle{
    display: flex; 
    align-items: center; 
    justify-content: space-between; 
    padding-right: 1rem; 
    padding-left: 0.5rem
}
.divChartRef{
    height: 450px;
    width: 100%;
}

@media screen and (max-width: 1200px) {
    .divChartRef{
        height: 250px;
        width: 100%;
    }
}
