.map-marker{
    margin-top: -35px;
    //background: blue;
    font-size: 12px !important;
    font-weight: 700 !important;
    color: red !important;
    padding: 0.2rem

},
.divGlobalMap{
    border-radius: 10px !important;
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
}

.spanFilter{
    margin-left: 10px !important;
}