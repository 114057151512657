.divPlantsFiltersV1{
    overflow: auto !important;
    width: 100% !important;
}

.divPlantsFiltersV2{
    display: flex !important; 
    flex-wrap: nowrap !important;
    width: 82vh !important;
    gap: 0.5rem !important
}
.spanFilter{
    margin-left: 10px !important;
}
.cardFilterPlant{
    display: flex !important;
    width: auto !important;
    padding: 5px !important;
}
