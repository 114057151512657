.spanMarcador{
    margin-right: 1rem !important;
}
.divTdPlano{
    margin-left: 1rem !important;
}
.divMarkImg{
    display: flex !important;
    gap: 0.2rem !important;
    align-items: center !important;
}
.divTdPercent{
    margin-left: 1rem !important;
}
.spanTdPercent{
    margin-left: 0.4rem !important;
}

.divTdAlarm{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.divTdButtons{
    pointer-events: all !important;
}

.divNotPlants{
    height: 10rem !important; 
    display: flex !important; 
    align-items: center !important; 
    margin-left: 1rem !important;
}
.disabled td {
    position: relative !important;
    pointer-events: none !important;
}

.disabled td::before {
    content: "" !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 98% !important;
    background-color: rgba(169, 169, 169, 0.3) !important;
    pointer-events: none !important;
  }

  .disabled:hover::before {
    background-color: rgba(169, 169, 169, 0.5) !important;
}