.divGlobalSelect{
    width: 100% !important;
    display: flex !important; 
    justify-content: end !important;
}

.buttonMainSelect{
    display: flex !important; 
    gap: 1rem !important; 
    align-items: center !important; 
    max-width: 100% !important; 
    min-width: 100% !important; 
    overflow: hidden !important; 
    justify-content: space-between !important;
}
.spanButtonMainSelect{
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 100% !important;
}
.spanButtonMainSelectV2{
    display: flex !important;
    align-items: center !important;
    gap: 1rem !important
}
.divOptionsSelectV1{
    padding: 1rem !important;
    width: 100% !important;
}
.divOptionsSelectV2{
    display: flex !important; 
    flex-direction: column !important; 
    justify-content: start !important; 
    align-items: start !important;
}
.divOptionsSelectV3{
    width: 100% !important;
    max-height: 15rem !important;
    overflow: auto !important;
}
.divSearchSelect{
    display: flex !important; 
    gap: 0.5rem !important; 
    margin-bottom: 0.7rem !important; 
    width: 100% !important;
}
.buttonAllSelect{
    display: flex !important; 
    gap: 1rem !important; 
    align-items: center !important; 
    width: 100% !important;
}
.divImgButtonSelect{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0.2rem !important
}
.spanButtonSelectedSelect{
    text-align: start !important;
}