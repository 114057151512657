.divSpinnerTablePlants{
    display: flex !important; 
    justify-content: center !important; 
    height: 30rem !important; 
    align-items: center !important
}
.spinnerTablePlants{
    width: 3rem !important; 
    height: 3rem !important;
}

.divH3Search{
    display: flex !important;
    align-items: center !important;
    gap: 2rem !important;
}

.divGlobalTitles{
    justify-content: space-between !important;
    width: 100% !important;
    display: flex !important;
    gap: 0.5rem !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.h3Title{
    flex: 1 !important;
    flex-direction: column !important;
}
.divSelectPortal{
    width: 100% !important;
}
.selectPortal{
    /*height: 3rem !important;*/
    width: 23rem !important;
}

.divPlantsFiltersV1{
    overflow: auto !important;
    width: 100% !important;
}


.divFiltersPlants{
    display: flex !important; 
    gap: 1rem !important; 
    align-items: center;
    flex-wrap: wrap !important
}
.spanFilter{
    margin-left: 10px !important;
}

.selectFilter{
    width: 100% !important;
}
.buttonFilterMark{
    width: 100% !important; 
    display: flex !important; 
    justify-content: space-between !important; 
    align-items: center !important
}
.optionButtonFilterMark{
    padding-left: 1rem !important;
}
.myTableResponsive{
    display: flex !important;
    flex-direction: column !important;
}

.divTable{
    min-height: 25rem !important; 
    max-height: 80vh !important;
    overflow: auto;
}

.cardFilterPlant{
    display: flex !important;
    width: auto !important;
    padding: 5px !important;
}

.divPlantsFiltersV2{
    display: flex !important; 
    flex-wrap: nowrap !important;
    width: 82vh !important;
    gap: 0.5rem !important
}


@media screen and (max-width: 1200px) {
    .divGlobalTitles {
        justify-content: space-between !important;
        width: 100% !important;
        display: flex !important;
        gap: 0.2rem !important;
        flex-direction: column !important;
        padding-top: 0.2rem !important;
        padding-bottom: 0.2rem !important;
    }
    .selectPortal{
        height: 2.5rem !important;
        width: 100% !important;
    }

    .divTable{
        min-height: 20vh !important; 
        max-height: 20vh !important;
        overflow: auto;
    }
}